import React from "react";
import defaultImage from "../../assets/default_image.jpeg";

const ImageFrame = ({ imageurl }) => {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  // console.log("Image URL is" + imageurl);

  return (
    <div className="float-right ml-4 mr-4 mb-4">
      <a
        href={
          imageurl === null || imageurl.length === 0
            ? "https://www.google.com"
            : imageurl[currentImageIndex]
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={
            imageurl === null || imageurl.length === 0
              ? defaultImage
              : imageurl[currentImageIndex]
          }
          alt="Current"
          width="200"
          className="rounded"
        />
      </a>

      {/* Dots */}
      <div className="flex justify-center mt-2">
        {imageurl.map((_, index) => (
          <span
            key={index}
            onClick={() => setCurrentImageIndex(index)}
            className={`inline-block w-2 h-2 mr-2 rounded-full cursor-pointer ${
              index === currentImageIndex
                ? "bg-emerald-500"
                : "bg-dark-secondary"
            }`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ImageFrame;

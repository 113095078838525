import React, { useState, useEffect } from "react";
import TopBar from "../components/devicespage/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faChevronDown,
  faPlus,
  faMagicWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { ApiAddress } from "../data/ApiAddress";
import GetIcon from "../components/devicespage/GetIcon";
import { useNavigate } from "react-router-dom";
import LoginToEdit from "../components/auth/LoginToEdit";
import { Oval } from "react-loading-icons";

const NewDevice = () => {
  const [categoriesInfo, setCategoriesInfo] = useState([]); // For getting a list of categories from the backend
  const [showCategories, setShowCategories] = useState(false); // For toggling the categories dropdown
  const [category, setCategory] = useState({
    category: "Microprocessor",
    icon: "microprocessor.svg",
  }); // For storing the selected category
  const [deviceName, setDeviceName] = useState("");
  const [subcategories, setSubcategories] = useState("");
  const [description, setDescription] = useState("");
  const userName = localStorage.getItem("username");
  const [alert, setAlert] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // Render a category dropdown
  const CategoryDropdown = ({ onSelect }) => {
    return (
      <div className="absolute top-full z-20 bg-dark-tertiary border border-dark rounded shadow-md">
        {categoriesInfo.map((cat, index) => (
          <div
            key={index}
            onClick={() => onSelect(cat.category)}
            className="cursor-pointer hover:bg-dark-secondary p-2"
          >
            <img
              src={GetIcon(cat.icon)}
              alt={cat.category}
              className="h-4 w-4 inline-block mr-2"
            />
            {cat.category}
          </div>
        ))}
      </div>
    );
  };

  const handleCategoryUpdate = (categoryName) => {
    // console.log(categoryName);

    // Find the category object in categoriesInfo based on categoryName
    const selectedCategory = categoriesInfo.find(
      (cat) => cat.category === categoryName
    );

    if (selectedCategory) {
      setCategory(selectedCategory);
    }
    // Update the category by checking in CategoriesInfo
    setShowCategories(false);
  };

  // Fetch devices from the backend when the component is mounted
  useEffect(() => {
    fetch(ApiAddress + "category_list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setCategoriesInfo(data);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, []);

  const handleChevronClick = () => {
    setShowCategories(!showCategories);
    // console.log("Chevron clicked!");
  };

  // Handle AI powered search
  const handleAIpoweredSearch = () => {
    // console.log("Doing AI powered device search...");
    if (deviceName === "" || category === "") {
      setAlert("Please provide device name and category.");
      return;
    }
    setIsLoading(true);

    //Make an API call to add the file here
    fetch(ApiAddress + "add_device_ai/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        deviceName: deviceName,
        categoryName: category.category,
        subcategories: subcategories,
        description: description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        // console.log(data);
        if (!data.error) {
          // navigate to the device page
          navigate("/devices/" + userName + "/" + deviceName);
        }

        if (data.error) {
          setAlert(data.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching devices:", error);
        setAlert((prevAlert) => "Error adding device!" + error);
        setIsLoading(false);
      });
  };

  const handleAddDeviceClick = () => {
    // console.log("Handle Add Device Clicked!");
    // console.log(deviceName);
    // console.log(category);
    // console.log(subcategories);
    // console.log(description);

    if (deviceName === "" || category === "") {
      setAlert("Please fill in all the required fields");
      return;
    }

    fetch(ApiAddress + "add_device/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        deviceName: deviceName,
        categoryName: category.category,
        subcategories: subcategories,
        description: description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (!data.error) {
          // navigate to the device page
          navigate("/devices/" + userName + "/" + deviceName);
        }

        if (data.error) {
          setAlert(data.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching devices:", error);
        setAlert((prevAlert) => "Error adding device!" + error);
      });
  };

  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-light-primary">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        <div className="flex flex-col items-center justify-left p-2 mt-10 border border-0 text-light-primary">
          {alert && <p className="text-sm text-red-500 font-light">{alert}</p>}
          <div className="justify-left">
            <h1 className="text-2xl font-bold">Create a new device</h1>
            <p className="text-sm text-dark-secondary font-light">
              A device will contain both files such as manuals and datasheet as
              well as codebase pulled from github.
            </p>

            {/* Labels */}
            <div className="flex justify-between mt-4">
              <label htmlFor="username" className="mr-2 font-bold">
                Username*
              </label>
              <label htmlFor="device-name" className="ml-2 font-bold">
                Device name*
              </label>
            </div>

            {/* Inputs */}
            <div className="flex items-center mt-2">
              <div className="relative mr-2">
                <FontAwesomeIcon
                  icon={faUser}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2"
                />
                <input
                  type="text"
                  id="username"
                  value={userName}
                  className="pl-8 py-1 border border-dark rounded-md bg-dark-secondary cursor-default focus:outline-none"
                  readOnly
                />
              </div>

              {/* Separator */}
              <div className="mx-2">/</div>

              <input
                type="text"
                id="device-name"
                maxLength={100}
                onChange={(e) => {
                  setAlert(null);
                  setDeviceName(e.target.value);
                }}
                placeholder="Enter device name"
                className="pl-8 py-1 flex-grow border border-dark rounded-md bg-dark-primary placeholder-dark ml-2 focus:outline-none focus:border-emerald-600"
              />
            </div>
            <p className=" text-sm text-dark-secondary font-light mt-2 b-2 ">
              Please use less than 100 characters for device name and use a
              descriptive name.
            </p>

            <div className="border-b border-dark mt-4"></div>

            {/* Categories and Subcategories */}
            <div className="mt-4">
              {/* Labels */}
              <div className="flex justify-between mt-4">
                <label htmlFor="category" className="mr-2 font-bold">
                  Category*
                </label>
                <label htmlFor="subcategories" className="ml-2 font-bold">
                  Subcategories (optional)
                </label>
              </div>

              {/* Input Fields */}
              <div className=" relative flex items-center mt-2">
                {/* <input
                  type="text"
                  id="category"
                  placeholder="Enter category"
                  className="py-1 border border-dark rounded-md bg-dark-primary placeholder-dark flex-grow mr-2"
                /> */}
                <button
                  onClick={handleChevronClick}
                  className={`flex items-center gap-2 px-4 py-1 rounded-full border border-1 border-dark hover:bg-dark-secondary`}
                >
                  <div>
                    <img
                      src={GetIcon(category.icon)}
                      alt={category.category}
                      className="h-4 w-4"
                    />
                  </div>
                  <p className="text-sm text-dark-secondary">
                    {category.category}
                  </p>

                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="text-xs text-dark-secondary hover:text-emerald-500"
                  />
                </button>

                {showCategories && (
                  <CategoryDropdown
                    onSelect={(category) => {
                      handleCategoryUpdate(category);
                    }}
                  />
                )}

                <input
                  type="text"
                  onChange={(e) => setSubcategories(e.target.value)}
                  id="subcategories"
                  placeholder="Enter subcategories"
                  className="py-1  pl-8 flex-grow border border-dark rounded-md bg-dark-primary placeholder-dark ml-2 focus:outline-none focus:border-emerald-600"
                />
              </div>

              {/* Labels  */}
              <div className="flex justify-between mt-2">
                <span className="text-sm text-dark-secondary font-light">
                  Category is required for each device
                </span>
                <span className="text-sm text-dark-secondary font-light">
                  Please enter comma separated values
                </span>
              </div>

              {/* Bottom Border */}
              <div className="my-4 border-b border-dark"></div>
            </div>

            {/* Labels */}

            {/* Categories and Subcategories */}
            <div className="mt-4">
              {/* Labels */}
              <div className="flex justify-between mt-4">
                <label htmlFor="category" className="mr-2 font-bold">
                  Description (optional)
                </label>
              </div>

              {/* Input Fields */}
              <div className=" relative flex items-center mt-2">
                <textarea
                  rows={5}
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  id="description"
                  placeholder=""
                  className="py-1  p-4 flex-grow border border-dark rounded-md bg-dark-primary placeholder-dark ml-0 focus:outline-none focus:border-emerald-600"
                />
              </div>
              {/* Create new device button */}
              {userName ? (
                <div className="relative flex items-center justify-end mt-4">
                  <button
                    onClick={handleAIpoweredSearch}
                    className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
                  >
                    {!isLoading ? (
                      <FontAwesomeIcon icon={faMagicWandSparkles} />
                    ) : (
                      <Oval className="h-[1rem] w-[1rem]" />
                    )}
                    {!isLoading
                      ? "Use AI powered search"
                      : "Searching the web..."}
                  </button>{" "}
                  <button
                    onClick={handleAddDeviceClick}
                    className="ml-4 text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Add manually
                  </button>
                </div>
              ) : (
                <div relative flex items-center justify-end mt-4 font-bold>
                  <LoginToEdit comment="add a device" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDevice;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"; // Change this to your desired icon
import { ApiAddress } from "../../data/ApiAddress";

const NewPost = ({ deviceName, setCurrentPosts, handleTabClick }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const handlePublishClick = () => {
    //Make an API call to add the post here
    fetch(ApiAddress + "add_post/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        deviceName: deviceName,
        title: title,
        content: content,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // Once the post is added, update the currentPosts list and setSelectedTab to "All"
        const newPost = data;
        setCurrentPosts((prevPosts) => [...prevPosts, newPost]);
        handleTabClick("All"); //Switch to "All" tab to see all the posts
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={200}
          className="w-full p-2 pl-3 pr-20 border rounded bg-dark-primary text-light-primary"
        />
        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-500">
          {title.length}/200
        </span>
      </div>
      <textarea
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className="w-full p-2 border rounded bg-dark-primary text-light-primary"
        rows={4}
      />
      <div className="flex justify-end">
        <button
          onClick={handlePublishClick}
          className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
        >
          <FontAwesomeIcon icon={faPaperPlane} />
          <span>Publish</span>
        </button>
      </div>
    </div>
  );
};

export default NewPost;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import LoginToEdit from "../auth/LoginToEdit";
import { ApiAddress } from "../../data/ApiAddress";
import { useContext } from "react";
import ErrorContext from "../docviewer/ErrorContext";

const GithubRepoComponent = ({ links, userName, deviceName }) => {
  const [showModal, setShowModal] = useState(false); //Github link modal

  const [currentLinks, setCurrentLinks] = useState(links);
  const [hoveredLink, setHoveredLink] = useState(null); // State for hovered link row

  const [inputLink, setInputLink] = useState(null);
  const setError = useContext(ErrorContext);

  // Handler for adding new github repo
  const handleAddRepo = (e) => {
    if (e.key === "Enter") {
      // console.log(inputLink);
      setInputLink(null); // Clear the input field
      setShowModal(false); // Hide the input field

      // Make an API call to add the new repo here

      fetch(ApiAddress + "add_githubrepo/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: localStorage.getItem("username"),
          deviceName: deviceName,
          repository: inputLink,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((data) => {
              throw new Error(data.error);
            });
          }
          if (!response.ok && response.status === 401) {
            // Token is probably expired. Remove local data and redirect to login.
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            // Assuming you're using React Router
            window.location.href = "/login"; // Redirect to login page
            throw new Error("Token has expired. Please login again.");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);

          // On success, add the new repo to your repos list
          if (!data.error) {
            setCurrentLinks((prevLinks) => [...prevLinks, data.message]);
          }
        })
        .catch((error) => {
          setError(error.message);
          console.error("Error fetching device:", error);
        });
    }
  };

  // Toggle the add new repo modal
  const handleAddRepoClick = () => {
    // console.log("adding new repo");
    setShowModal(!showModal);
  };

  // Handler for trash icon click
  const handleTrashClick = (link) => {
    // console.log(`Link: ${link}`);

    // Make an API call to delete the link here
    fetch(ApiAddress + "delete_githubrepo/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        deviceName: deviceName,
        repository: link,
      }),
    })
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // Token is probably expired. Remove local data and redirect to login.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          // Assuming you're using React Router
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Token has expired. Please login again.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);

        // On success, remove the link
        if (!data.error) {
          const updatedLinks = currentLinks.filter((l) => l !== link);
          setCurrentLinks(updatedLinks);
        }
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  return (
    <div className="text-sm text-light-primary">
      {currentLinks.length > 0 &&
        currentLinks.map((link, index) => (
          <div
            key={index}
            className="flex flex-row justify-between items-center m-2 p-2 rounded border border-dark shadow hover:bg-dark-secondary hover:text-white"
            onMouseEnter={() => setHoveredLink(index)}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <div className="flex justify-left items-center">
              <FontAwesomeIcon
                icon={faGithub}
                style={{ marginRight: "10px" }}
              />
              <a
                href={"https://github.com/" + link}
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
              >
                {link}
              </a>
            </div>
            <div className="flex justify-left">
              {/* Trash icon */}
              {hoveredLink === index &&
                localStorage.getItem("username") === userName && (
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="justify-right ml-10 cursor-pointer text-dark-secondary hover:text-emerald-500"
                    onClick={() => handleTrashClick(link)}
                  />
                )}
            </div>
          </div>
        ))}

      {/* Add new github repo button */}

      {localStorage.getItem("username") === userName ? (
        <div
          className="mt-2 text-center cursor-pointer"
          onClick={handleAddRepoClick}
        >
          <FontAwesomeIcon
            icon={faCirclePlus}
            className="text-dark-secondary hover:text-emerald-500"
          />
          <span className="text-dark-secondary font-light ml-2">Add</span>
        </div>
      ) : (
        <>
          {" "}
          {localStorage.getItem("username") === null ? (
            <LoginToEdit comment="add repo" />
          ) : null}
        </>
      )}

      {/* When show modal is true add an input bar to add a new github repo */}
      {showModal && (
        <div className="flex flex-col">
          <input
            type="text"
            onChange={(e) => setInputLink(e.target.value)}
            onKeyPress={handleAddRepo}
            placeholder="Enter <username>/<repo-name>"
            className={`m-2 p-2 bg-dark-primary rounded border border-dark `}
          />
        </div>
      )}
    </div>
  );
};

export default GithubRepoComponent;

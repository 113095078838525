import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { ApiAddress } from "../../data/ApiAddress";

const SourcesComponent = ({ links, userName, deviceName }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedLinks, setEditedLinks] = useState(links.join(", "));
  const [currentLinks, setCurrentLinks] = useState(links);

  const toggleEditMode = () => {
    if (isEditing) {
      // Convert editedLinks (comma-separated string) back to list
      const linkList = editedLinks.split(",").map((link) => link.trim());

      // console.log(linkList);
      // Update the local state with the new links
      setCurrentLinks(linkList);

      // Call the API here to save linkList or handle it accordingly
      // For now, let's assume you handle it on the parent and have a prop method called updateLinks
      // props.updateLinks(linkList);

      fetch(ApiAddress + "update_sources/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: localStorage.getItem("username"),
          deviceName: deviceName,
          sources: linkList,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          // console.log(deviceDetail);
        })
        .catch((error) => console.error("Error fetching device:", error));
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setEditedLinks(e.target.value);
  };

  return (
    <div className="text-sm text-light-primary break-words">
      {isEditing ? (
        <>
          <textarea
            value={editedLinks}
            onChange={handleInputChange}
            className="w-full h-32 bg-dark-tertiary opacity-75 p-2 rounded"
            placeholder="Enter links separated by commas..."
          />
          <p className="text-xs text-dark-secondary">
            Enter links separated by commas
          </p>
        </>
      ) : (
        <ul>
          {currentLinks.map((link, index) => (
            <li key={index}>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-light-primary no-underline"
              >
                [{index + 1}] {link}
              </a>
            </li>
          ))}
        </ul>
      )}
      {localStorage.getItem("username") === userName && (
        <button
          onClick={toggleEditMode}
          className="mt-2 text-dark-secondary hover:text-emerald-500"
        >
          <FontAwesomeIcon icon={isEditing ? faSave : faPencilAlt} />
          {isEditing ? " Save" : " Edit"}
        </button>
      )}
    </div>
  );
};

export default SourcesComponent;

import React from "react";
import { useParams } from "react-router-dom";
import TopBar from "../components/devicespage/TopBar";
import DeviceDetailsCard from "../components/devicedetails/DeviceDetailsCard";

const DeviceDetails = () => {
  let { userName, deviceName } = useParams();
  // console.log(userName);
  // console.log(deviceName);

  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-white">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        <DeviceDetailsCard userName={userName} deviceName={deviceName} />
      </div>
    </div>
  );
};

export default DeviceDetails;

import React, { useState, useEffect } from "react";
import { ApiAddress } from "../../data/ApiAddress";

const KnowledgebaseStatus = ({ userName, deviceName }) => {
  const [knowledgebaseStatus, setKnowledgebaseStatus] =
    useState("not available");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // console.log("Polling username: " + deviceName);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(ApiAddress + "knowledgebase_status/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            userName: userName,
            deviceName: deviceName,
          }),
        });
        const data = await response.json();

        if (!data.error) {
          setKnowledgebaseStatus(data.knowledgebase_status);
        }
        // console.log(data);
      } catch (error) {
        console.error("Error fetching device:", error);
      }
    };

    fetchStatus();
  }, [deviceName, userName]);

  const getStatusColor = (status) => {
    switch (status) {
      case "not available":
        return "from-gray-400 to-gray-600";
      case "in progress":
        return "from-orange-500 to-orange-700";
      case "available":
        return "from-emerald-500 to-emerald-700";
      default:
        return "from-gray-400 to-gray-600"; // default gradient
    }
  };

  //   const handleCreateKnowledgebaseClick = () => {
  //     console.log("Create knowledgebase clicked");
  //     console.log(deviceName);
  //   };

  const confirmContinue = () => {
    // console.log("Create knowledgebase clicked");
    // console.log(deviceName);

    setKnowledgebaseStatus("in progress");
    // Make an API call to create the knowledgebase here
    fetch(ApiAddress + "create_knowledgebase/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        deviceName: deviceName,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // On success, set the status to "in progress"
        if (data.error) {
          // setKnowledgebaseStatus("in progress");
          // console.log(data);
          setKnowledgebaseStatus("not available");
          return;
        }
      })
      .catch((error) => console.error("Error fetching device:", error));

    // Start polling the API every 2 seconds
    const interval = setInterval(() => {
      fetch(ApiAddress + "knowledgebase_status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //   Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: userName,
          deviceName: deviceName,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            setKnowledgebaseStatus(data.knowledgebase_status);

            // If status changes to "available", clear the interval
            if (data.knowledgebase_status === "available") {
              clearInterval(interval);
            }
          }
          // console.log(data);
        })
        .catch((error) => console.error("Error fetching device:", error));
    }, 2000);
  };

  const handleCreateKnowledgebaseClick = () => {
    // Only works if the user is logged in and the device was posted by the user
    if (userName === localStorage.getItem("username")) {
      setShowConfirmDialog(true);
    }
  };

  return (
    <>
      {showConfirmDialog && (
        <div className="fixed inset-0 z-10 flex items-center justify-center">
          {/* Backdrop */}
          <div className="absolute inset-0 bg-black opacity-80 backdrop-blur-md"></div>

          {/* Dialog */}
          <div className="relative bg-dark-primary border border-dark p-4 rounded shadow-lg z-20">
            <p className="text-light-primary pb-2 font-semibold">
              Are you sure you want to update Knowledebase for {deviceName}?
            </p>
            <p className="text-sm text-dark-secondary pb-2 font-light">
              It make take a few minutes to update the knowledgebase.
            </p>
            <div className="flex justify-end mt-2">
              <button
                className="text-sm flex items-center gap-2 mr-4 px-3 py-2 text-light-primary rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]"
                onClick={() => setShowConfirmDialog(false)}
              >
                Cancel
              </button>
              <button
                className="text-sm flex items-center gap-2 px-3 py-2 text-light-primary rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
                onClick={() => {
                  confirmContinue(); // Function that contains the delete logic
                  setShowConfirmDialog(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center">
        <span className="text-sm text-dark-secondary font-light mr-2">
          Knowledgebase{" "}
        </span>

        <button
          onClick={handleCreateKnowledgebaseClick}
          className={`rounded-full px-3 py-3 bg-gradient-to-br ${getStatusColor(
            knowledgebaseStatus
          )}`}
        ></button>
      </div>
    </>
  );
};

export default KnowledgebaseStatus;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faLink,
  faPlusCircle, //icon for add new file
  faUpload, // icon for file upload
  faTrash, // icon for delete/trash,
} from "@fortawesome/free-solid-svg-icons";
import { ApiAddress } from "../../data/ApiAddress";

import LoginToEdit from "../auth/LoginToEdit";

const FilesComponent = ({ files, userName, deviceName }) => {
  const [showModal, setShowModal] = useState(false); //File upload modal

  const [fileLink, setFileLink] = useState("");
  const [hoveredFile, setHoveredFile] = useState(null); // State for hovered file row

  const [currentFiles, setCurrentFiles] = useState(files);

  // Toggle adding and uploading files
  const handleAddFiles = () => {
    // console.log("adding new files");
    setShowModal(!showModal);
  };

  // Handler for trash icon click
  const handleTrashClick = (file) => {
    console.log(`Filename: ${file.file_name}, File URL: ${file.file_url}`);
    // Make an API call to delete the file here

    fetch(ApiAddress + "delete_file/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        deviceName: deviceName,
        fileName: file.file_name,
        fileUrl: file.file_url,
      }),
    })
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // Token is probably expired. Remove local data and redirect to login.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          // Assuming you're using React Router
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Token has expired. Please login again.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // On success, remove the file from your files list
        const updatedFiles = currentFiles.filter(
          (f) => f.file_name !== file.file_name
        );
        setCurrentFiles(updatedFiles);
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  // Handler for file upload to S3
  const handleUploadFile = (event) => {
    // console.log("Uploading files...");

    const files_to_upload = event.target.files;
    // console.log(files_to_upload);

    Array.from(files_to_upload).forEach((file, idx) => {
      const formData = new FormData();
      formData.append("userName", localStorage.getItem("username"));
      formData.append("deviceName", deviceName);
      formData.append("file", file);
      formData.append("rpath", file.name);
      console.log(formData);

      fetch(ApiAddress + "upload_file/", {
        method: "POST",
        headers: {
          // Only include the Authorization header (if needed)
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((response) => {
          if (!response.ok && response.status === 401) {
            // Token is probably expired. Remove local data and redirect to login.
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            // Assuming you're using React Router
            window.location.href = "/login"; // Redirect to login page
            throw new Error("Token has expired. Please login again.");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          // On success, add the file to your files list
          const newFile = {
            file_name: file.name,
            file_url: "",
          };

          setCurrentFiles((prevFiles) => [...prevFiles, newFile]);
        })
        .catch((error) =>
          console.error(
            "There was a problem with the fetch operation:",
            error.message
          )
        );
    });
  };

  const getUrlFileName = (url) => {
    const lastSlashPart = url.split("/").pop();
    const [fileName] = lastSlashPart.split("?");
    return fileName;
  };

  const handleAddLink = () => {
    const fileName = getUrlFileName(fileLink);

    // const fileName = fileLink.split("/").pop().split("?")[0]; // Extract file name from URL
    // Add the link and filename to your files list
    // setShowModal(false);
    // console.log(fileLink);
    // console.log(fileName);

    //Make an API call to add the file here
    fetch(ApiAddress + "add_file/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        deviceName: deviceName,
        fileName: fileName,
        fileUrl: fileLink,
      }),
    })
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // Token is probably expired. Remove local data and redirect to login.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          // Assuming you're using React Router
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Token has expired. Please login again.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // On success, add the file to your files list
        const newFile = {
          file_name: fileName,
          file_url: fileLink,
        };

        setCurrentFiles((prevFiles) => [...prevFiles, newFile]);
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  return (
    <div className="text-sm text-light-primary h-full">
      {/* Header */}
      <div className="flex justify-between bg-dark-secondary p-2 rounded">
        <div className="w-1/2">File</div>
        <div className="w-1/4">Size</div>
        <div className="w-1/4 text-center">Link</div>
      </div>
      {/* File Rows */}
      {files &&
        currentFiles.map((file, index) => (
          <div
            key={index}
            className="flex justify-between border-b border-dark p-2 relative"
            onMouseEnter={() => setHoveredFile(index)}
            onMouseLeave={() => setHoveredFile(null)}
          >
            <div className="w-1/2 flex items-center break-all">
              <FontAwesomeIcon icon={faFile} className="mr-2" />
              {file.file_name}

              {/* Trash icon */}
              {hoveredFile === index &&
                localStorage.getItem("username") === userName && (
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="justify-right ml-10 cursor-pointer text-dark-secondary hover:text-emerald-500"
                    onClick={() => handleTrashClick(file)}
                  />
                )}
            </div>
            <div className="w-1/4">{file.size}</div>
            <div className="w-1/4 text-center relative">
              <a
                href={file.file_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-light-primary no-underline inline-block"
              >
                <FontAwesomeIcon icon={faLink} />
              </a>
            </div>
          </div>
        ))}
      {/* Add New File Button */}
      {localStorage.getItem("username") === userName ? (
        <div className="mt-2 text-center">
          <button onClick={handleAddFiles} className="focus:outline-none">
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="text-dark-secondary hover:text-emerald-500"
            />
            <span className="text-dark-secondary font-light ml-2">Add</span>
          </button>
        </div>
      ) : (
        <>
          {" "}
          {localStorage.getItem("username") === null ? (
            <LoginToEdit comment="edit" />
          ) : null}
        </>
      )}
      {/* Upload file locally or just add a url*/}
      {showModal && (
        <div className="mt-2 flex justify-center align-center">
          {/* File upload button */}
          <label className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]">
            <FontAwesomeIcon icon={faUpload} className="cursor-pointer" />
            <input
              type="file"
              onChange={handleUploadFile}
              className="hidden"
              multiple
            />
            Upload file
          </label>
          <p className="text-light-primary align-center mt-2 mr-2 ml-2">OR</p>
          {/* Link input field */}
          <input
            type="text"
            placeholder="Provide a link..."
            value={fileLink}
            onChange={(e) => setFileLink(e.target.value)}
            className="border border-dark bg-dark-secondary p-1 rounded mr-2"
          />
          <button
            onClick={handleAddLink}
            className="text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
          >
            Add url
          </button>
        </div>
      )}
    </div>
  );
};

export default FilesComponent;

import React from "react";
import TopBar from "../components/devicespage/TopBar";
import Login from "../components/auth/Login";

const AuthPage = () => {
  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-white">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        <Login />
      </div>
    </div>
  );
};

export default AuthPage;

import React, { useEffect } from "react";
import GetIcon from "../devicespage/GetIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faRobot,
  faFolderPlus,
  faThumbsUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import {
  faBookOpen,
  faLink,
  faFile,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import { faGithub } from "@fortawesome/free-brands-svg-icons";

import DescriptionComponent from "./DescriptionComponent";
import SourcesComponent from "./SourcesComponent";
import FilesComponent from "./FilesComponent";
import GithubRepoComponent from "./GithubRepoComponent";
import CommunityComponent from "./CommunityComponent";
import { ApiAddress } from "../../data/ApiAddress";

import { useNavigate } from "react-router-dom";

import ImageFrame from "./ImageFrame";
import KnowledgebaseStatus from "./KnowledgebaseStatus";
import { Link } from "react-router-dom";

import ReactGA from "react-ga";

const DeviceDetailsCard = ({ userName, deviceName }) => {
  const [selectedLeftTab, setSelectedLeftTab] = React.useState("Description");
  const [selectedRightTab, setSelectedRightTab] = React.useState("Github");
  // const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [liked, setLiked] = React.useState(false); // tracking if the user has liked the card

  const [showCategories, setShowCategories] = React.useState(false); // For toggling the categories dropdown
  const [categoriesInfo, setCategoriesInfo] = React.useState([]); // For getting a list of categories from the backend

  // Initialize deviceDetail with default values to avoid errors when waiting for API calls
  const [deviceDetail, setDeviceDetail] = React.useState({
    imageurl: [],
    likes: "",
    knowledebase: false,
    lastupdate: "",
    icon: "",
    category: "",
    subcategories: [],
    description: "",
    sources: [],
    // files: [],
    community: [],
    // ... other fields
  });

  // For updating the subcategories
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [inputSubcategories, setInputSubcategories] = React.useState("");

  // For navigation purpose
  const navigate = useNavigate();

  // Fetch devicesdetail from the backend when the component is mounted
  useEffect(() => {
    const payload = {
      userName: userName,
      deviceName: deviceName,
    };

    fetch(ApiAddress + "device_detail/", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // console.log(deviceDetail);
        // setDeviceDetail(data);
        if (!data.error) {
          setDeviceDetail(data);
          if (data.description === "") {
            setDeviceDetail({
              ...data,
              description: "Please edit to add a description for this device.",
            });
          }
        }
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, [userName, deviceName]);

  // Handle the chevron click to change the category
  const handleChevronClick = () => {
    // console.log("chevron clicked");
    setShowCategories(!showCategories);
  };

  // Handle the plus click to change the sub category
  const handleAddSubCategoryClick = () => {
    setShowDropdown(!showDropdown);
  };

  // Fetch devices from the backend when the component is mounted
  useEffect(() => {
    fetch(ApiAddress + "category_list/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setCategoriesInfo(data);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, []);

  // Render a category dropdown
  const CategoryDropdown = ({ onSelect }) => {
    return (
      <div className="absolute top-full z-20 bg-dark-tertiary border border-dark rounded shadow-md">
        {categoriesInfo.map((cat, index) => (
          <div
            key={index}
            onClick={() => onSelect(cat.category)}
            className="cursor-pointer hover:bg-dark-secondary p-2"
          >
            <img
              src={GetIcon(cat.icon)}
              alt={cat.category}
              className="h-4 w-4 inline-block mr-2"
            />
            {cat.category}
          </div>
        ))}
      </div>
    );
  };

  const handleAddSubcategories = async (event) => {
    if (event.key === "Enter") {
      setShowDropdown(!showDropdown);
      // console.log(inputSubcategories);
      let newCategories = inputSubcategories
        .split(",")
        .map((item) => item.trim());
      deviceDetail.subcategories = newCategories;

      // API call to the backend
      const response = await fetch(ApiAddress + "update_subcategories/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`, // send the token for validation
        },
        body: JSON.stringify({
          userName: localStorage.getItem("username"),
          deviceName: deviceName,
          subcategories: inputSubcategories,
        }),
      });
      if (response.status === 401) {
        // Token is probably expired. Remove local data and redirect to login.
        localStorage.removeItem("username");
        localStorage.removeItem("token");
        window.location.href = "/login"; // Redirect to login page
        throw new Error("Token has expired. Please login again.");
      }
      const data = await response.json();
      if (response.status === 200) {
        // console.log(data);
        // let newCategories = data.message.split(",").map((item) => item.trim());
        // console.log(newCategories);
        // deviceDetail.subcategories = newCategories;
      } else {
        console.error("Error updating subcategories:", data.error);
      }
    }
  };

  const handleAskAIClick = () => {
    console.log("asking AI");
    ReactGA.event({
      category: userName,
      action: "Asked AI",
      label: deviceName,
    });

    navigate(`/devices/${userName}/${deviceName}/askai`);
  };

  const handleAddtoProjectClick = () => {
    // console.log("adding to project");
  };

  const handleThumbsUpClick = async () => {
    const action = liked ? "unlike" : "like"; // Determine the action based on current state

    // API call to the backend
    const response = await fetch(ApiAddress + "update_likes/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // send the token for validation
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        deviceName: deviceName,
        action: action,
      }),
    });
    if (response.status === 401) {
      // Token is probably expired. Remove local data and redirect to login.
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      window.location.href = "/login"; // Redirect to login page
      throw new Error("Token has expired. Please login again.");
    }

    const data = await response.json();
    if (response.status === 200) {
      // Toggle the like status on successful API response
      setLiked(!liked);
      if (action === "like") {
        deviceDetail.likes += 1;
      } else {
        deviceDetail.likes -= 1;
      }
    } else {
      console.error("Error updating likes:", data.message);
    }
  };

  // handle category update after selection
  const handleCategoryUpdate = (category) => {
    // console.log("Selected category:", category);

    // API call to the backend to update the category
    fetch(ApiAddress + "update_device_category/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        deviceName: deviceName,
        category: category,
      }),
    })
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // Token is probably expired. Remove local data and redirect to login.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          // Assuming you're using React Router
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Token has expired. Please login again.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // On success, update device icon and category
        if (!data.error) {
          const updatedDeviceDetail = {
            ...deviceDetail, // spreads the current state values
            icon: data.icon,
            category: data.category,
          };
          setDeviceDetail(updatedDeviceDetail);
        }
      })
      .catch((error) => console.error("Error fetching device:", error));

    setShowCategories(false); // Close dropdown after selection};
  };

  // const ImageFrame = (imageurl) => (
  //   <div className="float-right ml-4 mr-4 mb-4">
  //     <a
  //       href={imageurl[currentImageIndex]}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       <img
  //         src={imageurl[currentImageIndex]}
  //         alt="Current"
  //         width="200"
  //         className="rounded"
  //       />
  //     </a>

  //     {/* Dots */}
  //     <div className="flex justify-center mt-2">
  //       {imageurl.map((_, index) => (
  //         <span
  //           key={index}
  //           onClick={() => setCurrentImageIndex(index)}
  //           className={`inline-block w-2 h-2 mr-2 rounded-full cursor-pointer ${
  //             index === currentImageIndex
  //               ? "bg-emerald-500"
  //               : "bg-dark-secondary"
  //           }`}
  //         ></span>
  //       ))}
  //     </div>
  //   </div>
  // );

  return (
    <div className="mt-10 border border-0">
      <div className="flex flex-col sm:flex-row justify-between">
        {/* <!-- Header --> */}
        <h1 className="text-light-primary font-bold mb-4 flex items-center">
          {userName}/{deviceName}
          <button
            onClick={handleThumbsUpClick}
            className="text-sm text-dark-secondary focus:outline-none ml-4 hover:text-emerald-500"
          >
            <FontAwesomeIcon
              icon={faThumbsUp}
              className={`${
                liked ? "text-emerald-500" : "text-dark-secondary"
              }`}
            />
            <span className="text-dark-secondary text-sm ml-1 hover:text-emerald-500">
              {deviceDetail.likes}
            </span>
          </button>
        </h1>

        {/* <!-- Embeddings status --> */}
        {deviceDetail.knowledgebase && (
          <div className="flex justify-between space-x-1">
            <p className="text-dark-secondary text-xs mb-4"> Embeddings </p>
            <p className="text-dark-secondary text-xs mb-4">
              {" updated "} {deviceDetail.lastupdate}
            </p>
          </div>
        )}
      </div>

      <div className="flex flex-col sm:flex-row justify-between">
        {/* <!-- Your Category, Subcategories, and Add New Category button go here --> */}

        <div className=" relative flex flex-wrap items-center space-x-2 mb-4 sm:mb-0">
          <button
            onClick={handleChevronClick}
            className={`flex items-center gap-2 px-4 py-1 rounded-full border border-1 border-dark hover:bg-dark-secondary`}
          >
            <div>
              <img
                src={GetIcon(deviceDetail.icon)}
                alt={deviceDetail.category}
                className="h-4 w-4"
              />
            </div>
            <p className="text-sm text-dark-secondary">
              {deviceDetail.category}
            </p>

            <FontAwesomeIcon
              icon={faChevronDown}
              className="text-xs text-dark-secondary hover:text-emerald-500"
            />
          </button>

          {showCategories && (
            <CategoryDropdown
              onSelect={(category) => {
                handleCategoryUpdate(category);
              }}
            />
          )}

          {deviceDetail.subcategories.map((subcat, index) => (
            <button
              key={index}
              className={`flex items-center gap-2 px-4 py-1 rounded-full border border-1 border-dark`}
            >
              <p className="text-sm text-dark-secondary">{subcat}</p>
            </button>
          ))}

          {/* Add New Sub Category Button */}
          {userName === localStorage.getItem("username") && (
            <button
              onClick={handleAddSubCategoryClick}
              className="text-dark-secondary focus:outline-none"
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="hover:text-emerald-500"
              />
            </button>
          )}

          {/* Dropdown */}
          {showDropdown && (
            <input
              type="text"
              value={inputSubcategories}
              onChange={(e) => setInputSubcategories(e.target.value)}
              onKeyPress={handleAddSubcategories}
              placeholder="Enter comma separated text"
              className={`flex items-center gap-2 px-4 py-1 rounded border border-1 border-dark text-light-primary bg-dark-primary text-sm placeholder-dark outline-none focus:border-emerald-500`}
            />
          )}
        </div>

        <div className="flex items-center space-x-2">
          {/* <!-- Ask AI and Add to Project buttons go here --> */}
          <button
            className={`text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#696969] to-[#545F71] hover:from-[#5e5e5e] hover:to-[#4c5265]`}
            onClick={handleAskAIClick}
          >
            <FontAwesomeIcon icon={faRobot} />
            <p className="text-sm">Ask AI</p>
          </button>
          <Link to="/projects">
            <button
              className={`text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]`}
              onClick={handleAddtoProjectClick}
            >
              <FontAwesomeIcon icon={faFolderPlus} />
              <p className="text-sm">Add to Project</p>
            </button>
          </Link>

          {/* Knowledebase status button goes hereby */}
          <KnowledgebaseStatus userName={userName} deviceName={deviceName} />
        </div>
      </div>

      {/* <!-- Device Details --> */}
      <div className="mt-6 w-full min-h-[70vh] mb-10 border border-dark_github bg-dark-github px-4 py-4 flex flex-col sm:flex-row hover:shadow-[rgba(16,185,129,0.8)_0px_0px_5px]">
        {/* Content */}
        <div className="flex flex-col sm:flex-row flex-1 min-h-[65vh]">
          {/* Left Content */}
          <div className="w-full sm:w-3/4 pr-2 border-r-0 border-gray-300">
            <div className="flex flex-col h-full">
              <div className="flex flex-row space-x-4 mb-4 border-b border-dark pb-0">
                {[
                  { name: "Description", icon: faBookOpen },
                  { name: "Sources", icon: faLink },
                  { name: "Files", icon: faFile },
                  { name: "Community", icon: faUsers },
                ].map(({ name, icon }) => (
                  <button
                    key={name}
                    onClick={() => setSelectedLeftTab(name)}
                    className={`flex items-center py-2 px-4  ${
                      selectedLeftTab === name
                        ? "text-light-primary border-b-2 border-emerald-500"
                        : "text-dark-secondary border-b-2 border-transparent"
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={icon}
                      className={`${
                        selectedLeftTab === name
                          ? "text-light-primary"
                          : "text-dark-secondary"
                      }`}
                    />
                    <span className="ml-2 text-sm font-medium">{name}</span>
                  </button>
                ))}
              </div>
              {/* <div className="flex flex-col overflow-y-auto text-sm min-h-[calc(100%-2rem)]"> */}
              <div className="flex flex-col overflow-y-auto text-sm">
                {selectedLeftTab === "Description" &&
                deviceDetail.imageurl.length > 0 &&
                deviceDetail.description ? (
                  <div className="flex">
                    <div className="flex-1">
                      <DescriptionComponent
                        text={deviceDetail.description}
                        userName={userName}
                        deviceName={deviceName}
                      />
                    </div>
                    <ImageFrame imageurl={deviceDetail.imageurl} />
                  </div>
                ) : selectedLeftTab === "Description" &&
                  deviceDetail.description ? (
                  // <DescriptionComponent
                  //   text={deviceDetail.description}
                  //   userName={userName}
                  //   deviceName={deviceName}
                  // />
                  <div className="flex">
                    <div className="flex-1">
                      <DescriptionComponent
                        text={deviceDetail.description}
                        userName={userName}
                        deviceName={deviceName}
                      />
                    </div>
                    <ImageFrame imageurl={deviceDetail.imageurl} />
                  </div>
                ) : null}

                {selectedLeftTab === "Sources" && deviceDetail.sources ? (
                  <SourcesComponent
                    links={deviceDetail.sources}
                    userName={userName}
                    deviceName={deviceName}
                  />
                ) : null}

                {selectedLeftTab === "Files" && deviceDetail.files ? (
                  <FilesComponent
                    files={deviceDetail.files}
                    userName={userName}
                    deviceName={deviceName}
                  />
                ) : null}

                {selectedLeftTab === "Community" && deviceDetail.community ? (
                  <CommunityComponent
                    community={deviceDetail.community}
                    userName={userName}
                    deviceName={deviceName}
                  />
                ) : null}
              </div>
            </div>
          </div>

          {/* Right Content */}
          <div className="w-full sm:w-1/4 pl-2 mt-4 sm:mt-0">
            <div className="flex flex-col min-h-[65vh]">
              <div className="flex flex-row space-x-4 mb-4 border-b border-dark pb-0">
                {[
                  { name: "Github", icon: faGithub },
                  // {name: 'Reddit', icon: faReddit},
                  // {name: 'Comments', icon: faComment},
                ].map(({ name, icon }) => (
                  <button
                    key={name}
                    onClick={() => setSelectedRightTab(name)}
                    className={` flex items-center py-2 px-4 ${
                      selectedRightTab === name
                        ? "text-white border-b-2 border-emerald-500"
                        : "text-dark-secondary border-b-2 border-transparent"
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={icon}
                      className={`${
                        selectedRightTab === name
                          ? "text-light-primary"
                          : "text-dark-secondary"
                      }`}
                    />
                    <span className="ml-2 text-sm text-light-primary font-medium">
                      {name}
                    </span>
                  </button>
                ))}
              </div>
              <div className="overflow-y-auto text-sm min-h-[calc(100%-2rem)]">
                {selectedRightTab === "Github" && deviceDetail.github ? (
                  <GithubRepoComponent
                    links={deviceDetail.github}
                    userName={userName}
                    deviceName={deviceName}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetailsCard;

import React, { useState, useEffect } from "react";
import TopBar from "../components/devicespage/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faChevronDown,
  faPlus,
  faFolderPlus,
  faMagicWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { ApiAddress } from "../data/ApiAddress";
// import GetIcon from "../components/devicespage/GetIcon";
import { useNavigate } from "react-router-dom";
import LoginToEdit from "../components/auth/LoginToEdit";
// import { Oval } from "react-loading-icons";

const NewProject = () => {
  const [categoriesInfo, setCategoriesInfo] = useState([]); // For getting a list of categories from the backend
  const [showCategories, setShowCategories] = useState(false); // For toggling the categories dropdown
  const [category, setCategory] = useState({
    category: "Microprocessor",
    icon: "microprocessor.svg",
  }); // For storing the selected category
  const [projectName, setProjectName] = useState("");
//   const [subcategories, setSubcategories] = useState("");
  const [description, setDescription] = useState("");
  const userName = localStorage.getItem("username");
  const [alert, setAlert] = useState(null);

//   const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleAddProjectClick = () => {
    // console.log("Handle Add Device Clicked!");
    // console.log(projectName);
    // console.log(description);

    if (projectName === "" || category === "" || description === "") {
      setAlert("Please fill in all the required fields");
      return;
    }

    fetch(ApiAddress + "add_project/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: userName,
        projectName: projectName,
        description: description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (!data.error) {
          // navigate to the device page
          navigate("/projects/" + userName + "/" + projectName);
        }

        if (data.error) {
          setAlert(data.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching devices:", error);
        setAlert((prevAlert) => "Error adding device!" + error);
      });
  };

  return (
    <div className="flex flex-col w-full h-screen overflow-auto bg-dark-primary text-light-primary">
      <TopBar />
      <div className="w-4/5 mx-auto flex flex-col border border-0">
        <div className="flex flex-col items-center justify-left p-2 mt-10 border border-0 text-light-primary">
          {alert && <p className="text-sm text-red-500 font-light">{alert}</p>}
          <div className="justify-left">
            <h1 className="text-2xl font-bold">Create a new project</h1>
            <p className="text-sm text-dark-secondary font-light">
              A project will contain multiple devices where users can
              collaborate on hardware projects with their team.
            </p>

            {/* Labels */}
            <div className="flex justify-between mt-4">
              <label htmlFor="username" className="mr-2 font-bold">
                Username*
              </label>
              <label htmlFor="device-name" className="ml-2 font-bold">
                Project name*
              </label>
            </div>

            {/* Inputs */}
            <div className="flex items-center mt-2">
              <div className="relative mr-2">
                <FontAwesomeIcon
                  icon={faUser}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2"
                />
                <input
                  type="text"
                  id="username"
                  value={userName}
                  className="pl-8 py-1 border border-dark rounded-md bg-dark-secondary cursor-default focus:outline-none"
                  readOnly
                />
              </div>

              {/* Separator */}
              <div className="mx-2">/</div>

              <input
                type="text"
                id="project-name"
                maxLength={100}
                onChange={(e) => {
                  setAlert(null);
                  setProjectName(e.target.value);
                }}
                placeholder="Enter project name"
                className="pl-8 py-1 flex-grow border border-dark rounded-md bg-dark-primary placeholder-dark ml-2 focus:outline-none focus:border-emerald-600"
              />
            </div>
            <p className=" text-sm text-dark-secondary font-light mt-2 b-2 ">
              Please use a brief and descriptive name with less than 100
              characters for your project name.
            </p>

            <div className="border-b border-dark mt-4"></div>

            {/* Labels */}

            {/* Categories and Subcategories */}
            <div className="mt-4">
              {/* Labels */}
              <div className="flex justify-between mt-4">
                <label htmlFor="category" className="mr-2 font-bold">
                  Description*
                </label>
              </div>

              {/* Input Fields */}
              <div className=" relative flex items-center mt-2">
                <textarea
                  rows={5}
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  id="description"
                  placeholder="Please provide a description for your project"
                  className="py-1  p-4 flex-grow border border-dark rounded-md bg-dark-primary placeholder-dark ml-0 focus:outline-none focus:border-emerald-600"
                />
              </div>
              {/* Create new device button */}
              {userName ? (
                <div className="relative flex items-center justify-end mt-4">
                  <button
                    onClick={handleAddProjectClick}
                    className="ml-4 text-sm flex items-center gap-2 px-3 py-2 text-white rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Create new
                  </button>
                </div>
              ) : (
                <div relative flex items-center justify-end mt-4 font-bold>
                  <LoginToEdit comment="add a device" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProject;

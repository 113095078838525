import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginToEdit from "../auth/LoginToEdit";

import {
  faUser,
  faCalendarAlt,
  faComment,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ApiAddress } from "../../data/ApiAddress";

const PostChain = ({ post }) => {
  const computeDaysAgo = (dateString) => {
    const postDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInTime = currentDate - postDate;
    const differenceInDays = Math.floor(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    return differenceInDays === 0 ? "Today" : `${differenceInDays} days ago`;
  };

  // State to add and update replies
  const [currentReplies, setCurrentReplies] = useState(post.reply_list);
  const [newReply, setNewReply] = useState(""); // State for new reply
  const [hoveredReply, setHoveredReply] = useState(null); // State for hovered reply row

  const handleAddComment = () => {
    // console.log(post.id);
    // Make an API call to add the comment here
    fetch(ApiAddress + "add_reply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        postId: post.post_id,
        content: newReply,
      }),
    })
      .then((response) => {
        if (!response.ok && response.status === 401) {
          // Token is probably expired. Remove local data and redirect to login.
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          // Assuming you're using React Router
          window.location.href = "/login"; // Redirect to login page
          throw new Error("Token has expired. Please login again.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // On success, add the file to your files list
        setCurrentReplies((prevReplies) => [...prevReplies, data]);
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  // handler for trash icon click
  const handleTrashClick = (reply) => {
    // console.log(`Reply ID: ${reply.id}, Reply Content: ${reply.content}`);
    // Make an API call to delete the file here

    fetch(ApiAddress + "delete_reply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        userName: localStorage.getItem("username"),
        replyId: reply.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // On success, remove the reply from your replies list
        const updatedReplies = currentReplies.filter((r) => r.id !== reply.id);
        setCurrentReplies(updatedReplies);
      })
      .catch((error) => console.error("Error fetching device:", error));
  };

  return (
    <>
      <div>
        {/* Post header */}
        <div className="flex justify-left bg-gradient-to-br from-[#54E1AE] to-[#15BE81] p-0 rounded">
          <h2 className="text-sm flex items-center gap-2 px-3 py-2 text-light-primary font-bold">
            {post.title}
          </h2>
          <div className="flex space-x-4 text-xs">
            <div className="flex items-center space-x-2 text-dark-primary">
              <FontAwesomeIcon icon={faUser} />
              <span>{post.posted_by}</span>
            </div>
            <div className="flex items-center space-x-2 text-dark-primary">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>{computeDaysAgo(post.posted_on)}</span>
            </div>
          </div>
        </div>

        {/* Post content */}
        <div className="flex justify-left bg-dark-primary p-4 border border-dark">
          <p className="">{post.content}</p>
        </div>
      </div>

      {/* Replies Section */}
      <div className="mt-4 ml-4 border-l-2 border-gray-300 pl-4">
        {currentReplies.map((reply) => (
          <div
            key={reply.id}
            onMouseEnter={() => setHoveredReply(reply.id)}
            onMouseLeave={() => setHoveredReply(null)}
            className="mb-4"
          >
            {/* Reply header */}
            <div className="flex justify-between items-center bg-dark-secondary p-0 rounded">
              <div className="flex justify-left">
                <h3 className="text-sm flex items-center gap-2 px-3 py-2 text-light-primary font-medium">
                  Reply by {reply.posted_by}
                </h3>
                <div className="flex space-x-4 text-xs">
                  <div className="flex items-center space-x-2 text-dark-primary">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <span>{computeDaysAgo(reply.posted_on)}</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-left">
                {/* Trash icon */}
                {hoveredReply === reply.id &&
                  localStorage.getItem("username") === reply.posted_by && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="flex items-center cursor-pointer text-dark-secondary pr-4 hover:text-emerald-500"
                      onClick={() => handleTrashClick(reply)}
                    />
                  )}
              </div>
            </div>

            {/* Reply content */}
            <div className="flex justify-left bg-dark-primary p-4 border border-dark">
              <p className="">{reply.content}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Add Comment Section */}
      {localStorage.getItem("username") ? (
        <div className="mt-6 rounded border border-dark bg-dark-primary p-2">
          <div className="flex items-center gap-2">
            <textarea
              rows="3"
              className="flex-grow p-2 rounded bg-dark-primary text-light-primary resize-none"
              placeholder="Add a comment..."
              onChange={(e) => setNewReply(e.target.value)}
            ></textarea>
            <button
              onClick={handleAddComment}
              className="flex items-center gap-2 px-3 py-2 text-light-primary rounded bg-gradient-to-br from-[#54E1AE] to-[#15BE81] hover:from-[#4ad5a0] hover:to-[#12a973]"
            >
              <FontAwesomeIcon icon={faComment} />
              <span>Comment</span>
            </button>
          </div>
        </div>
      ) : (
        <LoginToEdit comment="comment" />
      )}
    </>
  );
};

export default PostChain;

import React, { useEffect, useState } from "react";
import { ApiAddress } from "../../data/ApiAddress";
import GetIcon from "../devicespage/GetIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import KnowledgebaseStatus from "../devicedetails/KnowledgebaseStatus";

const DeviceInfoHeader = ({ userName, deviceName }) => {
  // Initialize deviceDetail with default values to avoid errors when waiting for API calls
  const [deviceDetail, setDeviceDetail] = useState({
    imageurl: [],
    likes: "",
    knowledebase: false,
    lastupdate: "",
    icon: "",
    category: "",
    subcategories: [],
    description: "",
    sources: [],
    files: [],
    github: [],
    community: [],
    // ... other fields
  });

  const handleThumbsUpClick = () => {};

  // Fetch devicesdetail from the backend when the component is mounted
  useEffect(() => {
    const payload = {
      userName: userName,
      deviceName: deviceName,
    };

    fetch(ApiAddress + "device_detail/", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // console.log(deviceDetail);
        // setDeviceDetail(data);
        setDeviceDetail(data);
      })
      .catch((error) => console.error("Error fetching devices:", error));
  }, [userName, deviceName]);

  return (
    <div className="mt-10 border border-0">
      <div className="flex justify-between">
        {/* <!-- Header --> */}
        <h1 className="text-white font-bold mb-4">
          <Link to={`/devices/${userName}/${deviceName}`}>
            <span className="hover:text-emerald-500">
              {userName}/{deviceName}
            </span>
          </Link>
          <button
            onClick={handleThumbsUpClick}
            className="text-sm text-dark-secondary focus:outline-none ml-4 hover:text-emerald-500"
          >
            <FontAwesomeIcon
              icon={faThumbsUp}
              className={`${
                false ? "text-emerald-500" : "text-dark-secondary"
              }`}
            />
            <span className="text-dark-secondary text-sm ml-1 hover:text-emerald-500">
              {deviceDetail.likes}
            </span>
          </button>
        </h1>

        {/* <!-- Embeddings status --> */}
        {deviceDetail.knowledebase && (
          <div className="flex justify-between space-x-1">
            <p className="text-dark-secondary text-xs mb-4"> Embeddings </p>
            <p className="text-dark-secondary text-xs mb-4">
              {" updated "} {deviceDetail.lastupdate}
            </p>
          </div>
        )}
      </div>

      {/* <!-- Categories --> */}

      <div className="flex justify-between">
        {/* <!-- Your Category, Subcategories, and Add New Category button go here --> */}

        <div className=" relative flex items-center space-x-2">
          <button
            className={`flex items-center gap-2 px-4 py-1 rounded-full border border-1 border-dark cursor-default`}
          >
            <div>
              <img
                src={GetIcon(deviceDetail.icon)}
                alt={deviceDetail.category}
                className="h-4 w-4"
              />
            </div>
            <p className="text-sm text-dark-secondary">
              {deviceDetail.category}
            </p>
          </button>

          {deviceDetail.subcategories.map((subcat, index) => (
            <button
              key={index}
              className={`flex items-center gap-2 px-4 py-1 rounded-full border border-1 border-dark cursor-default`}
            >
              <p className="text-sm text-dark-secondary">{subcat}</p>
            </button>
          ))}
        </div>

        <div className="flex items-center space-x-2 -mb-0">
          {/* <!-- Ask AI and Add to Project buttons go here --> */}
          {/* Knowledebase status button goes hereby */}
          <KnowledgebaseStatus userName={userName} deviceName={deviceName} />
        </div>
      </div>
    </div>
  );
};

export default DeviceInfoHeader;

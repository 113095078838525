import microprocessor from "../../assets/microprocessor.svg";
import microcontroller from "../../assets/microcontroller.svg";
import sensors from "../../assets/sensors.svg";
import testandmeasurement from "../../assets/testandmeasurement.svg";
import fpga from "../../assets/fpga.svg";

const GetIcon = (iconName) => {
  switch (iconName) {
    case "microprocessor.svg":
      return microprocessor;
    case "microcontroller.svg":
      return microcontroller;
    case "sensors.svg":
      return sensors;
    case "testandmeasurement.svg":
      return testandmeasurement;
    case "fpga.svg":
      return fpga;
    default:
      return microprocessor; // default icon
  }
};

export default GetIcon;

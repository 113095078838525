import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { ApiAddress } from "../../data/ApiAddress";

const DescriptionComponent = ({ text, userName, deviceName }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);

  const formatText = (inputText) => {
    let formattedText = inputText.replace(/\n/g, "<br />");

    // Replace URLs with anchor tags
    formattedText = formattedText.replace(
      /\[([^\]]+)]\((https?:\/\/[^\s]+)\)/g,
      (fullMatch, description, url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color:#10b981">${description}</a>`
    );

    return formattedText;
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      // When toggling off from edit mode, make the API call to save changes here.
      fetch(ApiAddress + "update_description/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          userName: localStorage.getItem("username"),
          deviceName: deviceName,
          description: editedText,
        }),
      })
        .then((response) => {
          if (!response.ok && response.status === 401) {
            // Token is probably expired. Remove local data and redirect to login.
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            // Assuming you're using React Router
            window.location.href = "/login"; // Redirect to login page
            throw new Error("Token has expired. Please login again.");
          }
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          // console.log(deviceDetail);
        })
        .catch((error) => console.error("Error fetching device:", error));
    }
  };

  const handleChange = (e) => {
    setEditedText(e.target.value);
  };

  return (
    <div className="text-sm text-white break-words relative h-full p-0">
      <div className="flex flex-col mb-5"></div>
      {isEditing ? (
        <textarea
          value={editedText}
          onChange={handleChange}
          className="w-full h-[95vh] bg-dark-tertiary opacity-100 p-2 rounded"
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: formatText(editedText),
          }}
        />
      )}
      {localStorage.getItem("username") === userName && (
        <button
          className="absolute top-0 right-0 -mt-0 -mr-0 text-dark-secondary cursor-pointer hover:text-emerald-500"
          // className="text-dark-secondary cursor-pointer hover:text-emerald-500"
          onClick={handleEditToggle}
          title={isEditing ? "Save Description" : "Edit Description"}
        >
          <FontAwesomeIcon icon={isEditing ? faSave : faPencilAlt} />
          {isEditing ? " Save" : " Edit"}
        </button>
      )}
    </div>
  );
};

export default DescriptionComponent;

import React from "react";

const ScoreBox = ({ score }) => {
  const percentage = ((score + 1) / 2) * 100;

  // Linearly interpolate between red and green based on the score
  const red = Math.floor(255 * (1 - score));
  const green = Math.floor((255 * (1 + score)) / 2);

  const bgColor = `rgb(${red * 2}, ${green}, 0)`; // Using the interpolated red and green values

  return (
    <div className="flex items-center space-x-2">
      <div className="relative border border-1 rounded border-gray-300 text-xsm text-emerald-500 w-12 h-2 p-0 m-0">
        <div
          className="absolute left-0 top-0 h-full rounded p-0 m-0"
          style={{ width: `${percentage}%`, backgroundColor: bgColor }}
        ></div>
      </div>
      <span className="text-dark-secondary">{score.toFixed(2)}</span>
      {/* <span className="text-dark-secondary">{score}</span> */}
    </div>
  );
};

export default ScoreBox;

import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
const LoginToEdit = ({ comment }) => {
  return (
    <Link to={`/login`}>
      <div className="mt-2 text-center text-xs text-dark-secondary cursor-pointer">
        <FontAwesomeIcon
          icon={faCircleUser}
          className="text-dark-secondary mr-2"
        />
        Login to {comment}
      </div>
    </Link>
  );
};

export default LoginToEdit;

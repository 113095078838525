import React from "react";

const HistoryFilterButtons = ({ selectedFilterDate, onFilterChange }) => {
  return (
    <div className="flex mt-4 space-x-2 mb-2 justify-end items-center">
      <p className="text-emerald-500 mr-2"> History</p>
      <button
        className={`px-2 py-1 ${
          selectedFilterDate === 0
            ? "border-1 rounded-full bg-dark-secondary"
            : "border-1 rounded-full text-dark-secondary"
        }`}
        onClick={() => onFilterChange(0)}
      >
        Today
      </button>
      <button
        className={`px-2 py-1 ${
          selectedFilterDate === 7
            ? "border-1 rounded-full bg-dark-secondary"
            : "border-1 rounded-full text-dark-secondary"
        }`}
        onClick={() => onFilterChange(7)}
      >
        Last 7 Days
      </button>
      <button
        className={`px-2 py-1 ${
          selectedFilterDate === 30
            ? "border-1 rounded-full bg-dark-secondary"
            : "border-1 rounded-full text-dark-secondary"
        }`}
        onClick={() => onFilterChange(30)}
      >
        Last 30 Days
      </button>
      <button
        className={`px-2 py-1 ${
          selectedFilterDate === -1
            ? "border-1 rounded-full bg-dark-secondary"
            : "border-1 rounded-full text-dark-secondary"
        }`}
        onClick={() => onFilterChange(-1)}
      >
        All
      </button>
      <button
        className={`px-2 py-1 ${
          selectedFilterDate === -100
            ? "border-1 rounded-full bg-dark-secondary"
            : "border-1 rounded-full text-dark-secondary"
        }`}
        onClick={() => onFilterChange(-100)}
      >
        Hide history
      </button>
    </div>
  );
};

export default HistoryFilterButtons;

import React from "react";

const SampleQuestions = ({ deviceName }) => {
  const questionsList = [
    { question: `How do I get started with ${deviceName}?` },
    { question: `What GPIO pins should I use for Uart in ${deviceName}?` },
    { question: `Help me write code to setup WiFi on ${deviceName}.` },
  ];

  return (
    <div className="flex flex-col mt-4">
      <h1 className="text-semibold text-dark-secondary">
        {" "}
        You can ask questions like
      </h1>
      <div className="grid grid-cols-2 gap-4 mt-5">
        {questionsList.map((item, index) => (
          <div
            key={index}
            className="p-3 border border-dark bg-dark-tertiary rounded-lg text-dark-secondary"
          >
            {item.question}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SampleQuestions;
